<template>
  <div class="container entry">
    <b-carousel
      :controls="true"
      :indicators="true"
      id="index-carousel"
    >
      <b-carousel-slide img-alt="Pigeon" img-src="./../assets/img/pigeon-on-ivy.jpg"/>
      <b-carousel-slide img-alt="Man with Shotgun" img-src="./../assets/img/shooter.jpg"/>
      <b-carousel-slide img-alt="Gun Cartridges" img-src="./../assets/img/gun-cartridges.jpg"/>
      <b-carousel-slide img-alt="Pigeons in the Snow" img-src="./../assets/img/pigeons-in-the-snow.jpg"/>
    </b-carousel>
    <div class="box">
      <div class="home container entry">
        <div class="columns eight">
          <p>
            The Woodpigeon is quickly proving itself to be the ultimate sporting quarry. Fast flying, sharp
            eyed,
            completely unpredictable, and totally wild, the Pigeon is sure to thoroughly test your shooting and
            hunting skills to the limit! Often referred to as the 'poor mans grouse', there is certainly nothing
            poor about the sport that this bird has to offer!
          </p>
          <p>
            Not only that but have a look at our rates and I'm sure you'll agree once you have had a days
            shooting
            with us, a driven pheasant day costing much more does not even come close both in terms of value for
            money or sport. We shoot over 170,000 acres / 80,000 hectares of prime farmland in central southern
            England (Oxfordshire, Buckinghamshire, Gloucestershire, Berkshire and Hampshire).
          </p>
          <p>
            We have accumulated over 30 years of in-field experience and have been arranging sport for clients
            since
            1996. We can cater for the single gun or parties of up to 6. We are very experienced in dealing with
            English & foreign hunters and are able to arrange everything needed for your days pigeon decoying.
          </p>
          <p>
            Pigeon Shooting bookings are available all year, 5 days a week (NB we do NOT shoot on Sundays and
            Mondays). There is no close season on Pigeon and this is our full time occupation. Based in the
            heart of
            Oxfordshire (with incredibly easy access from the M40, A34 and A43) we are less than one hour away
            from
            London and all major airports.
          </p>
          <p>
            For travellers we can arrange superb local B&B accommodation available from &pound;35-&pound;50
            through
            to hotel
            accommodation at approx &pound;60 to &pound;85 per person per night.
          </p>
        </div>

        <div class="columns four omega">
          <img alt="Pigeon in a Tree" height="219" src="./../assets/img/pigeon-in-a-tree-small.jpg" width="250"
               class="thumb"/>
        </div>

        <div class="columns four omega">
          <a href="http://www.youtube.com/profile?user=ukpigeonguide" class="no-style" target="_blank" rel="noopener">
            <img alt="YouTube" height="78" src="./../assets/img/youtube.png" width="176"/>
          </a>
          <br>
          <br>
          <a href="https://www.facebook.com/pages/Thames-Valley-Pigeon-Shooting/388414151288634" class="no-style"
             target="_blank" rel="noopener">
            <img alt="FaceBook" height="38" src="./../assets/img/FindUsOnFacebook.png" width="150"/>
          </a>
          <br>
          <br>
          <a href="https://twitter.com/ukpigeonguide" class="no-style" target="_blank" rel="noopener">
            <img alt="Twitter" height="61" src="./../assets/img/Twitter_logo_blue.png" width="75"/>
          </a>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Home",
    head: {title: "Home"}
  }
</script>
